import { FleamarketSearchErrorResponseDetailsTypeEnum } from '../../__codegen__/__openapi__/search-front-server'

const GRPC_CUSTOM_ERROR_TYPE = 'type.googleapis.com/search.error.v1.ErrorDetail'

export const PRE_DEFINED_ERROR_TYPE = {
  LONG_TAIL_QUERY: {
    TYPE: FleamarketSearchErrorResponseDetailsTypeEnum.LONG_TAIL_QUERY,
    MESSAGE: '검색어가 너무 길어요. 다른 검색어로 검색해주세요.', //TODO 메시지 수정 필요
  },
  NULL_QUERY: {
    TYPE: FleamarketSearchErrorResponseDetailsTypeEnum.NULL_QUERY,
    MESSAGE: '검색어를 입력해주세요.',
  },
  UNAUTHENTICATED: {
    TYPE: FleamarketSearchErrorResponseDetailsTypeEnum.UNAUTHENTICATED,
    MESSAGE: '로그인 후 이용해주세요.',
  },
  ABUSER_DETECTED: {
    TYPE: FleamarketSearchErrorResponseDetailsTypeEnum.ABUSER_DETECTED,
    MESSAGE: '검색에 실패했어요. 잠시 후 다시 시도해주세요.',
  },
  UNKNOWN: {
    TYPE: FleamarketSearchErrorResponseDetailsTypeEnum.UNSPECIFIED,
    MESSAGE: '검색에 실패했어요. 잠시 후 다시 시도해주세요.',
  },
} as const

type PreDefinedErrorType =
  (typeof PRE_DEFINED_ERROR_TYPE)[keyof typeof PRE_DEFINED_ERROR_TYPE]['TYPE']

export interface collectionWithError {
  details: {
    '@type': string
    type: PreDefinedErrorType
  }[]
}

export const getPreDefinedServiceError = (collection: collectionWithError) => {
  if (!collection?.details) return undefined
  const errorDetail = collection.details?.filter(
    (errorDetail: any) => GRPC_CUSTOM_ERROR_TYPE === errorDetail['@type']
  )
  return {
    type: errorDetail?.[0]?.type ?? PRE_DEFINED_ERROR_TYPE.UNKNOWN.TYPE,
  }
}
