import to from 'await-to-js'
import axios from 'axios'

import {
  NETWORK_TIMEOUT,
  SEARCH_FRONT_SERVER_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeUserAgentPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeCommonHeadersPlugin,
  plantaeSearchOriginPlugin,
  plantaeKarrotUserIdPlugin,
} from '../../plantae/plugins'
import { FleamarketApi } from '../../__codegen__/__openapi__/search-front-server/api/fleamarket-api'
import { addExperimentXSearchHeader } from '../../_app/utils/addCustomHeader'
import type { SearchExperimentReferrerType } from '../../referrer/types'

let serviceCache: ServiceKeywordNotificationType | null = null
export type ServiceKeywordNotificationType = ReturnType<
  typeof ServiceKeywordNotification
>

export function getServiceKeywordNotification({
  app,
  user,
}: {
  app: AppType
  user: UserType
}) {
  if (serviceCache) {
    return serviceCache
  }

  return (serviceCache = ServiceKeywordNotification({
    baseUrl: SEARCH_FRONT_SERVER_ENDPOINT,
    app,
    user,
  }))
}

const ServiceKeywordNotification = ({
  baseUrl,
  app,
  user,
}: {
  baseUrl: string
  app: AppType
  user: UserType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }
  const axiosInstance = axios.create({
    headers,
    timeout: NETWORK_TIMEOUT,
  })

  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: [
      plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
      plantaeRequestIdPlugin(),
      plantaeKarrotSessionIdPlugin({ app }),
      plantaeUserAgentPlugin({ userAgent: app.userAgent }),
      plantaeKarrotUserIdPlugin({ app, userId: user.id }),
      plantaeCommonHeadersPlugin(),
      plantaeSearchOriginPlugin(),
    ],
  })

  const client = new FleamarketApi(undefined, baseUrl, axiosInstance)

  return {
    async createUserKeywordNotification({ keyword }: { keyword: string }) {
      const [error, resp] = await to(
        client.apiV1FleamarketKeywordSearchUserKeywordsPost({
          where: 'search_result',
          screenName: 'search',
          apiV1FleamarketKeywordSearchUserKeywordsPostRequest: {
            keyword: keyword,
          },
        })
      )

      if (error || !resp?.data) {
        captureException(error)
        return null
      }

      return resp.data
    },
    async getKeywordNotificationInfo({
      keyword,
      referrer,
    }: {
      keyword: string
      referrer: {
        experiment: SearchExperimentReferrerType
      }
    }) {
      const [error, resp] = await to(
        client.apiV1FleamarketKeywordNotificationInfoGet(
          {
            keyword,
          },
          {
            headers: addExperimentXSearchHeader(referrer.experiment),
          }
        )
      )

      if (error || !resp?.data) {
        captureException(error)
        return null
      }

      return resp.data
    },
  }
}
